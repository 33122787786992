import { lazy } from 'react'

// ** React Imports
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

// ** Redux Imports
import { store } from "./redux/store"
import { Provider } from "react-redux"

// ** ThemeColors Context

import { ThemeContext } from "@context/ThemeColors"

import ModalContextProvider from "@context/ModalContext"
import { AuthProvider } from "@context/AuthContext";


// ** ThemeConfig
import themeConfig from "./configs/themeConfig"

// ** Toast
import { Toaster } from "react-hot-toast"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"
import '@styles/react/libs/flatpickr/flatpickr.scss'

// ** Service Worker
import * as serviceWorker from "./serviceWorker"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <Provider store={store}>
        <ThemeContext>
          <AuthProvider>
            <ModalContextProvider>
              <LazyApp />
              <Toaster
                position={themeConfig.layout.toastPosition}
                toastOptions={{ className: "react-hot-toast" }}
              />
            </ModalContextProvider>
          </AuthProvider>
        </ThemeContext>
    </Provider>
  </BrowserRouter>
)

serviceWorker.unregister()
