import { createContext, useState, useEffect } from "react";

// hooks imports
import useApi from "@hooks/useApi"
import useLoader from "@hooks/useLoader"
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import dayjs from "dayjs";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );

    const [user, setUser] = useState({});
    const [accessToken, setAccessToken] = useState({})

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const logoutUser = () => {
        setAuthTokens(null)
        setUser({})
        setAccessToken({})
        localStorage.removeItem("authTokens")
        navigate('/login')
    }

    async function getUser(empId) {
        setLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}user/logged/${empId}`, {
            refresh: authTokens?.refresh,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
        })
        const { success, data } = response.data
        if (success) {
            setUser(data)
        }else{
            
        }
        setLoading(false)
    }


    const contextData = {
        loading,
        user,
        authTokens,
        accessToken,
        setUser,
        setAuthTokens,
        logoutUser,
        setAccessToken
    };

    useEffect(() => {

        if (authTokens?.access) {
            let token = jwtDecode(authTokens.access)
            setAccessToken(token)
        }

        let localToken = JSON.parse(localStorage.getItem("authTokens"))
        let accToken = accessToken?.access || localToken?.access
        if (accToken ) {
            let encToken = jwtDecode(accToken)
            if (dayjs.unix(encToken?.exp).diff(dayjs()) < 1 == false) {
                getUser(encToken.emp_id)
            }else{
                navigate('/login')
            }
        }

    }, [authTokens])


    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};
