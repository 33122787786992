import { Spinner } from "reactstrap"

// ** Logo
const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader" style={{backgroundColor: "#161d31"}}>
      <img className="fallback-logo m-0 p-0" src={process.env.PUBLIC_URL + '/images/agaar.svg'} alt="logo" width={68} height={68} />
      <div className="p-2">
        <Spinner
        color="primary"
          style={{
            // color: "white",
            width: "3rem",
            height: "3rem"
          }} />
      </div>
      {/* <div className="loading"> */}
      {/* <div className="effect-1 effects"></div> */}
      {/* <div className="effect-2 effects"></div> */}
      {/* <div className="effect-3 effects"></div> */}
      {/* </div> */}
    </div>
  )
}

export default SpinnerComponent
