import { Spinner } from "reactstrap"
const ComponentSpinner = () => {
  return (
    <div className="suspense-loader">
        <Spinner color="" size='sm'/>
    </div>
  )
}

export default ComponentSpinner
