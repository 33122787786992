
import { Spinner } from "reactstrap"

export default function CSpinner(props) {

    const {
        color='white',
        height="2rem",
        width="2rem",
        text="Уншиж байна ..."
    } = props

    return (
        <>

            <div className='text-center' style={{ }}>
                <Spinner
                    // color="primary"
                    style={{
                        color: "white",
                        height: '2rem',
                        width: '2rem'
                    }}
                />
                <div className="pt-50" style={{ fontWeight: "500", fontSize: "14px", color: "white" }}>{text}</div>
            </div>
        </>
    )
};

